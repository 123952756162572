@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* @media (prefers-color-scheme: dark) {
  .element {
    color-scheme: dark;
  }
} */

.font-display {
  font-family: 'Raleway';
}

@layer base {
  :root {
    --hs-banner-color: theme(colors.pwhite);
    --hs-banner-text-color: theme(colors.pgray.800);
    --hs-banner-accept-text-color: theme(colors.pwhite);
    --hs-banner-accept-color: theme(colors.pgreen.500);
    --hs-banner-accept-border-color: theme(colors.pgreen.500);
    --hs-banner-decline-text-color: theme(colors.pwhite);
    --hs-banner-decline-color: theme(colors.pgray.300);
    --hs-banner-decline-border-color: theme(colors.pgray.300);
  }

  body.dark {
    --hs-banner-color: theme(colors.pgray.900);
    --hs-banner-text-color: theme(colors.pwhite);
    --hs-banner-accept-text-color: theme(colors.pwhite);
    --hs-banner-accept-color: theme(colors.pgreen.500);
    --hs-banner-accept-border-color: theme(colors.pgreen.500);
    --hs-banner-decline-text-color: theme(colors.pwhite);
    --hs-banner-decline-color: theme(colors.pgray.800);
    --hs-banner-decline-border-color: theme(colors.pgray.800);
  }

  .mdown {
    @apply dark:text-pwhite text-pgray-800;
  }

  .mdown p {
    @apply mb-5 dark:text-pwhite text-pgray-800;
  }

  .mdown h6 {
    @apply text-lg sm:text-lg leading-tight mb-5;
  }

  .mdown h5 {
    @apply text-xl sm:text-xl leading-tight mb-5 mt-12;
  }

  .mdown h4 {
    @apply text-2xl sm:text-2xl leading-tight mb-5;
  }

  .mdown h3 {
    @apply text-3xl sm:text-3xl leading-tight mb-5;
  }

  .mdown h2 {
    @apply text-3xl sm:text-4xl leading-tight mb-5;
  }

  .mdown h1 {
    @apply text-3xl sm:text-5xl leading-tight mb-5;
  }

  .mdown hr {
    @apply dark:text-pgray-700 text-pgray-200 my-5;
  }

  .mdown a {
    @apply text-pblue-500 font-normal hover:underline;
  }

  .mdown ul {
    @apply list-disc list-inside mb-5 pl-3;
  }

  .mdown ol {
    @apply list-decimal list-inside mb-5 pl-3;
  }

  .mdown ol li {
    @apply mb-2
  }

  .mdown ol li p {
    @apply mb-0 inline;
  }

  .mdown .tag {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: theme(colors.pgray.200);
    border-radius: 6px;
  }

  .dark .mdown .tag {
    background-color: theme(colors.pgray.600);
  }

  .dark {
    background-color: theme(colors.pgray.900);
  }
  

  .mdown-lg {
    @apply dark:text-pwhite text-pgray-800;
  }

  .mdown-lg p {
    @apply mb-5 last:mb-0 text-3xl leading-10 dark:text-pwhite/70 text-pgray-800;
  }

  .mdown-lg h6 {
    @apply text-lg sm:text-lg leading-tight mb-5;
  }

  .mdown-lg h5 {
    @apply text-xl sm:text-xl font-medium leading-tight mb-5;
  }

  .mdown-lg h4 {
    @apply text-2xl sm:text-2xl font-medium leading-tight mb-5 dark:text-pwhite/70 text-pgray-800/70;
  }

  .mdown-lg h3 {
    @apply text-3xl sm:text-3xl leading-tight mb-5;
  }

  .mdown-lg h2 {
    @apply text-3xl sm:text-4xl leading-tight mb-5;
  }

  .mdown-lg h1 {
    @apply text-3xl sm:text-5xl leading-tight mb-5;
  }

  .mdown-lg hr {
    @apply dark:text-pgray-700 text-pgray-200 my-5;
  }

  .mdown-lg a {
    @apply text-pblue-500 font-normal hover:underline;
  }

  .mdown-lg ul {
    @apply list-disc text-3xl dark:text-pwhite/70 leading-10 mb-5 pl-3;
  }

  .mdown-lg ol {
    @apply list-decimal text-3xl leading-10 mb-5 pl-3;
  }

  .mdown-lg ol li {
    @apply mb-2
  }

  .mdown-lg ol li p {
    @apply mb-0 inline;
  }

  .mdown-lg .tag {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: theme(colors.pgray.200);
    border-radius: 6px;
  }

  .dark .mdown-lg .tag {
    background-color: theme(colors.pgray.600);
  }

  /* div#hs-eu-cookie-confirmation {
    @apply dark:bg-pgray-800
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
    @apply dark:!text-pwhite
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner button#hs-eu-confirmation-button, div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner button#hs-eu-decline-button {
    @apply dark:border-2 dark:border-pwhite
  }

  div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner button#hs-eu-decline-button {
    @apply dark:!text-pwhite
  }

  #hs-banner-parent div#hs-eu-cookie-confirmation {
    @apply dark:bg-pgray-800
  }

  #hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p {
    @apply dark:!text-pwhite
  }

  #hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner button#hs-eu-confirmation-button, div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner button#hs-eu-decline-button {
    @apply dark:border-2 dark:border-pwhite
  }

  #hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner button#hs-eu-decline-button {
    @apply dark:!text-pwhite
  } */
}


@layer utilities {
  .filter-pgray-300 {
    filter: grayscale(100%) invert(85%) sepia(12%) saturate(298%) hue-rotate(174deg) brightness(93%) contrast(92%);
    -webkit-filter: grayscale(100%) invert(85%) sepia(12%) saturate(298%) hue-rotate(174deg) brightness(93%) contrast(92%);
  }

  .filter-pgray-400 {
    filter: grayscale(100%) invert(67%) sepia(18%) saturate(423%) hue-rotate(175deg) brightness(94%) contrast(82%);
    -webkit-filter: grayscale(100%) invert(67%) sepia(18%) saturate(423%) hue-rotate(175deg) brightness(94%) contrast(82%);
  }

  .filter-pgray-500 {
    filter: grayscale(100%) invert(42%) sepia(21%) saturate(582%) hue-rotate(176deg) brightness(96%) contrast(90%);
    -webkit-filter: grayscale(100%) invert(42%) sepia(21%) saturate(582%) hue-rotate(176deg) brightness(96%) contrast(90%);
  }

  .filter-pwhite {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }

  /* .marquee-text-wrap {
    overflow: hidden
  } */

  .bg-iridescent {
    background: linear-gradient(134.74deg, #00d1ea 9.43%, #f868ec 47.36%, #f98c2a 80.86%);
  }

  .text-iridescent {
    background: linear-gradient(134.74deg, #00d1ea 9.43%, #f868ec 47.36%, #f98c2a 80.86%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -o-text-fill-color: transparent;
    text-fill-color: transparent;
    display: inline-block;
  }


  .w-infinity {
    width: 100000px
  }

  .h-infinity {
    height: 100000px
  }

  .scroll-left-animation {
    -webkit-animation-name: scroll-animation;
    animation-name: scroll-animation;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    float: left
  }

  .scroll-bottom-animation {
    -webkit-animation-name: scroll-animation2;
    animation-name: scroll-animation2;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    float: left
  }

  .animation-dur-40s {
    -webkit-animation-duration: 40000ms;
    animation-duration: 40000ms;
  }

  .scroll-hidden::-webkit-scrollbar {
    display: none;
  }

  .accordion a {
    color: theme(colors.pblue.500)
  }

  .shiny_effect {
    --px: 0;
    --py: 0;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  @media (hover: hover) {
    .shiny_effect:hover:after {
      opacity: 0.2;
    }
  }

  .shiny_effect:after {
    --bgColor: rgba(255, 255, 255, 0.5);
    content: '';
    pointer-events: none;
    top: 0;
    left: 0;
    transition: opacity 0.3s linear;
    position: absolute;
    width: 200%;
    height: 200%;
    background: radial-gradient(var(--bgColor) 0%, transparent 100%);
    opacity: 0;
    z-index: 9999;
    transform: translateX(calc(var(--px) * 1px)) translateX(-50%) translateY(calc(var(--py) * 1px)) translateY(-50%);
  }

  /* .dark .shiny_effect:after {
    --bgColor: rgba(255, 255, 255, 0.5);
    content: '';
    pointer-events: none;
    top: 0;
    left: 0;
    transition: opacity 0.3s linear;
    position: absolute;
    width: 200%;
    height: 200%;
    background: radial-gradient(var(--bgColor) 0%, transparent 100%);
    opacity: 0;
    z-index: 9999;
    transform: translateX(calc(var(--px) * 1px)) translateX(-50%) translateY(calc(var(--py) * 1px)) translateY(-50%);
  } */

  .bg-cone {
    pointer-events: none;
    user-select: none;
    --top: theme(colors.pwhite);
    --bottom: theme(colors.pgray.300);
    --first: conic-gradient(from 90deg at 80% 50%, var(--top), var(--bottom));
    --second: conic-gradient(from 270deg at 20% 50%, var(--bottom), var(--top));
    -webkit-mask-image: radial-gradient(100% 50% at center center, black, transparent);
    background-image: var(--first), var(--second);
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%, 100%;
    background-position-y: 0%, 0%;
    background-size: 50% 100%, 50% 100%;
    /* transform: translateY(0px) rotate(180deg) translateZ(0px); */
    transform-origin: center center;
    background-repeat: no-repeat;
  }

  .dark .bg-cone {
    pointer-events: none;
    user-select: none;
    --top: theme(colors.pgray.600);
    --bottom: theme(colors.pgray.900);
    --first: conic-gradient(from 90deg at 80% 50%, var(--top), var(--bottom));
    --second: conic-gradient(from 270deg at 20% 50%, var(--bottom), var(--top));
    -webkit-mask-image: radial-gradient(100% 50% at center center, black, transparent);
    background-image: var(--first), var(--second);
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%, 100%;
    background-position-y: 0%, 0%;
    background-size: 50% 100%, 50% 100%;
    /* transform: translateY(0px) rotate(180deg) translateZ(0px); */
    transform-origin: center center;
    background-repeat: no-repeat;
  }

}

/* .marquee-text-paused .marquee-text-text {
    -webkit-animation-play-state: paused;
    animation-play-state: paused
  } */

@-webkit-keyframes scroll-animation {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@keyframes scroll-animation {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@-webkit-keyframes scroll-animation2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }
}

@keyframes scroll-animation2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }
}

.fade-in {
  opacity: 0;
  /* transition: opacity 1s ease-in-out; */
}
 
 .fade-in.is-visible {
  opacity: 0.9999;
}